/**
 * @constant API_HOSTNAME
 * Replace with your Google AppScript Link
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxBl6tz8f9FP3nvnBkztHxpdE5UmQWh5NU21ScXextAGK12QdJVJF3TnQ84ycT68JYw/exec`;

/**
 * @constant RSVP_STATUS
 * DO NOT CHANGES!
 */
export const RSVP_STATUS = {
  attend_holy: 'ATTEND_HOLY_MATRIMONY',
  attend_reception: 'ATTEND_RECEPTION',
  attend_both: 'ATTEND_BOTH',
  notAttend: 'NOT_ATTEND',
  sent: 'SENT',
  opened: 'OPENED',
  waiting: 'WAITING',
};

/**
 * @constant ACTION_POST_TYPE
 * DO NOT CHANGES!
 */
export const ACTION_POST_TYPE = {
  insertGuest: 'insert_guest',
  insertWish: 'insert_wish',
  insertGiftConfirmation: 'insert_gift_confirmation',
  updateRsvp: 'update_rsvp',
};

/**
 * @constant ACTION_GET_TYPE
 * DO NOT CHANGES!
 */
export const ACTION_GET_TYPE = {
  read: 'read',
  readQrCode: 'read_qrcode',
};

/**
 * @constant API_TABLE_TYPE
 * DO NOT CHANGES!
 */
export const API_TABLE_TYPE = {
  wishes: 'WISHES',
  rsvp: 'RSVP',
  giftConfirmation: 'GIFT_CONFIRMATION',
};
